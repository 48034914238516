/* src/DesignComponent.css */

.destination-placeholder-header {
  display: flex;
  flex-Wrap: wrap;
  justify-content: space-between;
  margin-bottom: '20px'
}

.design-component {
  text-align: center;
  padding: 2% 5%;
  background-color: var(--lightblueBgColor);
  font-family: Arial, sans-serif;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.boxes {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.box1 {
  /* background-color: var(--lightBlueColor); */
  padding: 20px 35px;
  border-radius: 20px;
  width: 100%;
  text-align: left;
  background-color: var(--card);

}

.box2 {
  text-align: left;
  background-color: var(--card);
  padding: 20px 35px;
  border-radius: 20px;
  width: 49%;
}

.box h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
}

.icon {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  font-size: 20px;
  color: #007bff;
}

.item p {
  margin: 0;
  font-size: 16px;
  color: #454545;
}

.box:nth-child(2) .icon {
  color: #ff4d4d;
}

@media (max-width: 768px) {
  .boxes {
    flex-direction: column;
    align-items: center;
  }

  .destination-placeholder-header {
    justify-content: center;

  }

  .destination-placeholder-header button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .box {
    width: 100%;
  }

  .box1 {
    width: 80%;
  }

  .box2 {
    width: 80%;
  }
}

/* Responsive design */
@media (max-width: 480px) {
  .boxes {
    flex-direction: column;
    align-items: center;
  }

  .destination-placeholder-header {
    justify-content: center;

  }

  .destination-placeholder-header button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .box {
    width: 100%;
  }

  .box1 {
    width: 100%;
  }

  .box2 {
    width: 100%;
  }
}