.selectedbundle-email {
    margin-top: 10px;
}

.selectedbundle-textfield {
    margin: 20px 0px;
}

.data-plan {
    background-color: var(--mainColor);
    padding: 3px 25px;
    border-radius: 15px;
}

.selectedbundle-flex-main {
    flex-wrap: wrap;
}

.selected-flex {
    display: flex;
    justify-content: space-between;
}

.selectedbundle-card {
    padding: 10px;
    width: 100%;

}

.Bundle-main-card {
    border-radius: 15px;
}

.align-bundle-text {
    text-align: left;
}

.price-selectedbundle-flex {
    display: flex;
    justify-content: space-between;
}

.selectedbundle-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    gap: 30px;
}

.align-success-text {
    text-align: left;
}