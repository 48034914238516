.Installationstep-main {
  text-align: center;
  padding: 2% 5%;
}

.Installationstep-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 20px;
  margin: 4% 21%;
}

.Installationstep-textheader {
  text-align: center;
}

.Installationstep-phone-image {
  margin-right: 65px;
}

.Installationstep-phone-image img {
  width: 150px;
  align-self: flex-start;
}

.Installationstep-steps {
  margin-left: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.Installationstep-step {
  display: flex;
  min-height: 420px;
  /* align-items: center; */
  /* margin-bottom: 50px; */
  align-items: flex-start;
  position: relative;
}

.Installationstep-step:last-child {
  margin-bottom: 0;
}

.destination-headertext {
  font-size: 35px;
}


.Installationstep-step-content {
  text-align: left;
}

.Installationstep-step-content .para {
  margin: 5px 0;
  color: #454545;
}


.Installationstep-Accordion {
  padding: 20px 0px;

}

.Installationstep-border {
  position: absolute;
  left: -102px;
  top: 20px;
  bottom: 20px;
  width: 5px;
  border-radius: 25px;
  background-color: var(--darkGray);
}

.Installationstep-circle {
  height: 30px;
  width: 30px;
  background-color: var(--mainColor);
  border-radius: 50%;
  position: absolute;
  left: -114px;
  z-index: 999999;
}


.number {
  color: #dddbdb;
  /* position: absolute;
  top: 0;
  left: 0; */
}

.text {
  /* position: relative;
  margin-top: 33px; */
  color: #454545;
  /* Adjust this to position text correctly */
}


.get-started-img-block {
  height: 80vh;
  position: relative;
}

.row-new {
  display: flex;
  flex-wrap: wrap;
}

.get-started-left-bl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  width: 100%;
  height: calc(100vh - 98px);
  top: 98px;
}

.get-started-img {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}

.get-started-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

@media (min-width: 1200px) {
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
}


@media (min-width: 1200px) {
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (max-width: 1600px) {
  .Installationstep-header {
    margin: 4% 24%;
  }

}

@media (max-width: 1520px) {
  .Installationstep-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 20px;
    margin: 4% 22%;
  }

}

@media (max-width: 1400px) {
  .Installationstep-header {
    margin: 4% 20%;
  }

}

@media (max-width: 1300px) {
  .Installationstep-header {
    margin: 4% 18%;
  }

}

@media (max-width: 1200px) {
  .Installationstep-header {
    margin: 4% 16%;
  }

}

@media (max-width: 1100px) {
  .Installationstep-header {
    margin: 4% 14%;
  }
}

@media (max-width: 1050px) {
  .Installationstep-main {
    padding: 2% 5%;
  }

  .Installationstep-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 4% 0%;

  }

  .Installationstep-phone-image {
    margin: 0 0 20px 0;
  }

  .Installationstep-steps {
    margin-left: 15px;

  }

  .Installationstep-circle {
    display: none;
  }

  .Installationstep-border {
    display: none;
  }

}

@media (max-width: 480px) {
  .Installationstep-phone-image img {
    width: 100px;
  }

  .get-started-left-bl {
    display: none;
  }

  .Installationstep-phone-image {
    margin-right: 0px;
  }

  .Installationstep-step {
    min-height: 210px;
  }

  .homescreen-subtitle {
    display: none;
  }

  .destination-headertext {
    font-size: 18px;
  }
}


/* animation */
.get-started-img {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  /* For 3D effect */
  transition: background-color 0.5s ease;
}

.get-started-img .flip-container {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
}

.get-started-img .flip-container.flipped {
  transform: rotateY(180deg);
  /* Flip the image */
}

.get-started-img img {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Prevents the back of the image from showing during flip */
}

.get-started-img img.back {
  transform: rotateY(180deg);
  /* Flip the back image */
}

.get-started-img.active {
  background-color: #f0f0f0;
  /* Optional background color */
}