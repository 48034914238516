.MuiTypography-h6 {
    font-family: var(--FontFamily_2) !important;
}

.commonmodel-title {
    color: var(--navLinkHoverColor);
    padding: 0;
    padding: 8px 0px;
    margin: 0px 24px;

}

.main-modal-wrapper{
    background-color: var(--mainBgColor);
    /* min-width: 1200px;    */
}
@media (max-width: 1024px) {
    .main-modal-wrapper .close-icon {
      padding: 0px;
    }
}
@media (max-width: 500px) {
    .MuiDialog-paper {
        margin: 10px !important;
    }
}