.bg-light-pink {
    padding: 60px 30px 30px;
    background: var(--card);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-right {
    width: 50%;
    padding: 0px 10px;
}

.row-left {
    width: 50%;
    padding: 0px 8px;

}

.white-box {
    background-color: var(--lightAccent);
    border-radius: 15px;
    padding: 30px;
    height: 100%;
}

.heading-four {
    font-size: 24px;
    color: #202020;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 16px;

}

.data-plan-box {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.fw-semibold {
    font-weight: 600;
}

.plan-name {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.d-block {
    display: block !important;
}

.text-black {
    color: #171717;
}

.detailed-list {
    margin-bottom: 16px;
}

.detailed-list li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 35px;
    padding: 16px 0;
    border-bottom: 1px solid #d9d9d9;
}

.modal-footer {
    margin: 0px 35px;
    padding: 30px;
    /* display: block; */
    background-color: var(--lightAccent);
    border-radius: 15px;
}

.modal-footer-left {
    width: 50%;
}

.modal-footer-right {
    width: 50%;
}

.button-buy-now {
    width: 50%;
    margin-left: auto;
}

ul {
    padding: 0px !important;
}

p {
    margin: 0px;
}

.heading-three {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    color: #202020;
}

.margin-b-h {
    margin-bottom: 16px;
}

.c-icon {
    width: 40px;
    height: 40px;
}

.network-item {
    padding: 10px 10px;
    margin: 5px 0px;
    border-radius: 10px;
    background-color: var(--mainColor);
}

.network-modal-content {
    min-width: 450px;
}

.network-heading {
    text-align: center;
    margin: 5px 0px;
}

.view-click {
    cursor: pointer;
}

@media (max-width: 1100px) {

    .row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .row-right {
        width: 100%;
        margin: 10px 0px
    }

    .row-left {
        width: 100%;
        margin: 10px 0px
    }

    .modal-footer {
        margin: 0px 30px;

    }
}

@media (max-width: 600px) {

    .bg-light-pink {
        padding: 30px 0px 0px;
    }
    .modal-footer {
        margin: 0px 0px;
        width: 100%;

    }
    .white-box {
     
        padding: 30px 15px;
    }
    .network-modal-content {
        min-width: 300px;
        width: 100%;
    }
}
@media (max-width: 600px) {

 
    .network-modal-content {
        min-width: 100%;
        width: 100%;
    }
}
        