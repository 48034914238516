.destinations {
  text-align: center;
  background-color: var(--mainColor);
  padding: 2% 5%;
}

.destination-cards {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: center;
  gap: 20px;
}

.destination-card {
  background: var(--card);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 228px;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 10px;
  min-width: 228px;
  position: relative;
}

.destination-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.destination-flag-cnt {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.flag {
height: 60px;
}

.country {
  margin-bottom: 5px;
  text-align: left;
}

.price {
  color: var(--blackColor);
}

.destination-arrow {
  display: flex;
  justify-content: end;
  margin-top: auto;
}

.destination-headertext {
  text-align: left;
}

.view-all-btn {
  margin-top: 20px;

}

.destination-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .destination-cards {
    flex-direction: column;
    align-items: center;
  }

  .destination-card {
    width: 90%;
  }

  .destination-header-main {
    text-align: center;
    flex-direction: column;
  }

  .destinations {

    padding: 5% 5%;
  }
  .destination-tab-align {
    width: 90%;
    
  }
}