.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 10px 20px;
    border: 1px solid var(--darkGray);
    background-color:var(--card);
    border-radius: 3px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: var(--mainColor);
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  
  @media (max-width: 768px) {
    .pagination button {
      padding: 8px 8px;
    }
  }