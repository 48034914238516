.data-plan {
    background-color: var(--mainColor);
    padding: 3px 25px;
    border-radius: 15px;
}

.bundle-flex-main {
    flex-wrap: wrap;
    overflow-x: auto;
    height: 70vh;
}

.icon-plan-flex {
    display: flex;
    justify-content: space-between;
}

.bundle-card {
    background: var(--card);
    padding: 10px;
    width: 23vw;


}

.Bundle-main-card {
    border-radius: 15px;
}

.align-bundle-text {
    text-align: left;
}

.price-bundle-flex {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.bundle-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    position: relative;
    padding: 5px 0px;
}

.bundle-text {
    cursor: pointer;
}

.Roaming-main {
    margin: 30px 10px;
}

.roaming-country {
    background-color: var(--lightPurple);
    width: 129px;
    height: 115px;
    border-radius: 10px;
    border: 2px solid var(--mainColor);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.roaming-heading {
    text-align: center;
    margin: 10px 0px;
}

.roaming-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.roaming-text {
    cursor: pointer;
}

@media (max-width: 1300px) {
    .bundle-card {
        background: var(--card);
        padding: 10px;
        width: 39vw;
    }


}

@media (max-width: 1100px) {
    .bundle-card {
        background: var(--card);
        padding: 10px;
        width: 38vw;
    }


}

@media (max-width: 768px) {
    .bundle-card {
        background: var(--card);
        padding: 10px;
        width: 77vw;
    }


}