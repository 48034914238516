/* PaymentComponent.css */
.payment-card {
  background-color: var(--card);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
  font-family: 'Arial', sans-serif;
}

.payment-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.payment-form {
  display: flex;
  flex-direction: column;
}

.payment-button {
  background-color: #6772e5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.payment-button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.payment-button:hover:not(:disabled) {
  background-color: #5469d4;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}