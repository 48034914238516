.modal-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1000 !important;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.popup-wrap {
  padding: 20px 40px;
  margin: 100px 0;
  position: relative;
  max-width: 1285px;
  display: flex;
  background-color: var(--whiteColor);
  justify-content: center;
  border-radius: 10px;
}

.trasparent-popup {
  padding: 0px;
  background-color: transparent !important;
}

.popup-wrap:focus-visible {
  outline: none;
}

.gray-close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  right: -25px;
  width: 22px;
  cursor: pointer;
  /* margin-top: -30px */
}

.gray-close-ic-lib {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  right: -2.8%;
  width: 22px;
  cursor: pointer;
  /* margin-top: -30px */
}

.gray-close-ic-for-location {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

@media (max-width: 1500px) {
  /* 
  .popup-wrap {
    max-width: 1400px;
  } */

}

@media (max-width: 1199px) {
  .popup-wrap {
    max-width: 964px;
    /* min-width: 850px; */
  }
}

@media (max-width: 991px) {
  .popup-wrap {
    max-width: 900px;
    /* min-width: 680px; */
  }
}

@media (max-width: 768px) {
  .popup-wrap {
    max-width: 600px;
    /* min-width: 600px; */
  }
}