.form_field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}

.form_field:last-child {
    margin-bottom: 0;
}

.form_field label {
    font-weight: 700;
    display: flex;
}

.field-left {
    width: 100%;
    font-family: var(--FontFamily_1);
    text-align: left;
    font-weight: 700;
}

.field-right {
    width: 100%;
    text-align: start;
    margin-bottom: 0px;
    padding-top: 7px;
    position: relative;
}

.custom-radio-singale {
    position: relative;
}

.custom-radio-singale input[type="radio"] {
    position: absolute;
    top: 0;
    left: -999999px;
}

.field-right .custom-radio-singale {
    display: inline-block;
    vertical-align: middle;
}

/* .custom-radio-singale label:hover,
.custom-radio-singale label:active,
.custom-radio-singale input[type="radio"]+label:hover,
.custom-radio-singale input[type="radio"]+label:active {
    font-weight: 700;
} */

.custom-radio-singale input[type="radio"]+label {
    position: relative;
    padding-left: 25px;
    visibility: visible !important;
    line-height: 20px;
    cursor: pointer;
    font-family: var(--FontFamily_1);
    font-weight: 500;
}

.custom-radio-singale input[type="radio"]+label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 100%;
}

.custom-radio-singale input[type="radio"]+label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--ThirdButtonColor);
    border-radius: 100%;
}

.custom-radio-singale input[type="radio"]+label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border: 6px solid var(--ThirdButtonColor);
    border-radius: 100%;
    transform: scale(0);
}

.custom-radio-singale input[type="radio"]:checked+label:after {
    transform: scale(1);
}

/* .custom-radio-singale+.custom-radio-singale {
    margin-left: 40px;
} */