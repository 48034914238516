*,
*::before,
*::after {
    box-sizing: border-box;
}

.articles {
    display: grid;
    /* max-width: 1200px; */
    margin-inline: auto;
    padding: 0px;
    grid-template-columns: repeat(4, 1fr);
    /* Force 4 columns */
    gap: 24px;
}


.DestinationCard-button {
    margin-top: 15px;
}
article {
    --img-scale: 1.001;
    --title-color: black;
    --link-icon-translate: -20px;
    --link-icon-opacity: 0;
    position: relative;
    border-radius: 16px;
    box-shadow: none;
    background: var(--card);
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.article-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: var(--darkGray);
}

/* basic article elements styling */
article h2 {
    margin: 0 0 18px 0;
    font-size: 1.3rem;
    color: var(--main-font);
    transition: color 0.3s ease-out;
}

figure {
    /* margin: 0; */
    /* padding: 0; */
    /*aspect-ratio: 5 / 5;
    overflow: hidden; */
    /* min-height: 215px; */
    aspect-ratio: 5/5;
}

article .portfolio-image {
    max-width: 100%;
    aspect-ratio: auto;
    max-height: 100%;
    transform-origin: center;
    border-radius: 15px;
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
}

.article-body {
    padding: 18px 24px;
    flex-grow: 1;
    background-color: var(--mainColor);
    text-align: center;
    height: 25%;
}

.article-body p {
    color: var(--secondary-color);
    font-weight: 400;
    margin-bottom: 0%;
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
    --img-scale: 1.1;
    --title-color: var(--heighlight-font);
    --link-icon-translate: 0;
    --link-icon-opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

/************************ 
  Generic layout (demo looks)
**************************/

.heshtag {
    gap: 8px;
    margin: 10px 20px;
    flex-flow: column;
    margin-top: 25px;
}

.heshtag-second {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
}

.heshtag-second p {
    padding-right: 15px;
}

.heshtag p {
    color: var(--heighlight-font);
    margin: 0%;
    font-size: 14px;
}

/* @container card (min-width: 380px) {
    .article-wrapper {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 16px;
    }

    .article-body {
        padding-left: 0;
    }

    figure img {
        height: 100%;
        aspect-ratio: 1;
        height: 100%;
        object-fit: cover;
    }
} */

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
@media screen and (max-width: 960px) {
    article {
        container: card/inline-size;
    }
}
@media screen and (max-width: 1200px) {
    .articles {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}
@media screen and (max-width: 1024px) {
    .articles {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}