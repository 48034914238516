.eSIMCardDetail-mainContainer {
  background-color: var(--lightenOrange);
  padding: 20px;
  border-width: 1px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  margin: 30px 0px;
}

.eSIMCardDetail-rightContainer {
  width: 40%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.eSIMCardDetail-Qrbottomtext {
  margin: 18px 40px;
  text-align: justify;
}

.eSIMCardDetail-progress {
  width: 100%;
}

.eSIMCardDetail-button-flex {
  display: flex;
  margin: 18px 40px;
  gap: 10px;
  width: 80%;
}

.eSIMCardDetail-logo {
  width: 50%;
}

.eSIMCardDetail-LeftContainer {
  width: 60%;

}

.eSIMCardDetail-title {
  margin-bottom: 10px;
}

.eSIMCardDetail-content {
  margin-bottom: 15px;
}

.eSIMCardDetail-list {
  list-style-type: decimal;
  margin-bottom: 15px;
  padding-left: 20px;
}

.eSIMCardDetail-list li {
  margin-bottom: 10px;
}

.eSIMCardDetail-button {
  cursor: pointer;
  margin: 5px 0px;
}

.emsimcard-tag-failure {
  background-color: var(--redIconColor);
  padding: 10px;
  border-radius: 10px;
  margin-left: 3px;
}

.emsimcard-tag-success {
  background-color: var(--greenColor);
  padding: 10px;
  border-radius: 10px;
  margin-left: 3px;
}

.tag-flex {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .eSIMCardDetail-mainContainer {
    padding: 2% 4%;
    flex-direction: column;
  }

  .eSIMCardDetail-LeftContainer {
    width: 100%;
    margin-bottom: 20px
  }

  .eSIMCardDetail-rightContainer {
    width: 100%;

  }

}
@media (max-width: 450px) {
  .eSIMCardDetail-button-flex {
    width: 100%;
  }
  .eSIMCardDetail-Qrbottomtext {
    margin: 10px 0px;
    text-align: justify;
  }
}

@media (max-width: 380px) {
  .eSIMCardDetail-mainContainer {
    padding: 2% 3%;
    flex-direction: column;
  }
  .eSIMCardDetail-button-flex {
    flex-direction: column;
  }
}