.ESIMCard-mainContainer {
  background-color: var(--lightenOrange);
  padding: 20px;
  border-width: 1px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  margin: 30px 0px;
}

.ESIMCard-rightContainer {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.esimcard-button-flex {
  display: flex;
  margin-top: 18px;
  gap: 10px;
  width: 100%;
}
.ESIMCard-logo{
  width: 50%;
}
.ESIMCard-LeftContainer {
  width: 70%;

}

.ESIMCard-title {
  margin-bottom: 10px;
}

.ESIMCard-content {
  margin-bottom: 15px;
}

.ESIMCard-list {
  list-style-type: decimal;
  margin-bottom: 15px;
  padding-left: 20px;
}

.ESIMCard-list li {
  margin-bottom: 10px;
}

.ESIMCard-button {
  cursor: pointer;
  margin: 5px 0px;
}
.emsimcard-tag-failure {
  background-color: var(--redIconColor);
  padding: 5px;
  border-radius: 10px;
  margin-left: 3px;
}
.emsimcard-tag-success {
  background-color: var(--greenColor);
  padding: 5px;
  border-radius: 10px;
  margin-left: 3px;
}

.tag-flex {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .ESIMCard-mainContainer {
      padding: 2% 4%;
      flex-direction: column;
  }
  .ESIMCard-LeftContainer {
    width: 100%;
  
  }
  .ESIMCard-rightContainer {
    width: 100%;
  
  }
  
}