.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: var(--lightblueBgColor);
  border-radius: 5px;
  font-family: Arial, sans-serif;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--lightblueBgColor);
}

.breadcrumb-separator {
  margin: 0 5px;
  color: var(--DarkGrayTextColor);
}

/* Responsive design */
@media (max-width: 768px) {
  /* .breadcrumbs {
    flex-direction: column;
    align-items: flex-start;
  } */

  .breadcrumb-item {
    margin-bottom: 5px;
  }

  .breadcrumb-separator {
    display: none;
  }

  .breadcrumb-item::after {
    content: ">";
    margin-left: 5px;
    color: #999;
  }
}