/* src/Footer.css */

.footer-main-container {
  background-color: var(--lightBlueColor);
  padding: 4% 5%;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  border-width: 5px;
  justify-content: space-between;
}

.footer-section {
  flex: 1;
  text-align: left;
  margin: 10px;
}

.footer-section h2 {
  margin-bottom: 20px;
}

.logo-text {
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  color: #979797;
  font-weight: 700;
}

.footer-section ul li a {
  margin-bottom: 10px;
  color: #979797;
  text-decoration: none;
  font-weight: 700;
}


.footer-section p {
  margin: 0 0 10px 0;
  color: #979797;
  font-weight: 700;
}


.logo img {

  width: 75px;
  height: 55px;
  margin-bottom: 25px;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons i {
  color: var(--text);
  font-size: 24px;
}

.newsletter form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.newsletter input {
  padding: 20px;
  flex: 1;
  border: 0px;
  background-color: var(--card);
  border-radius: 10px;
}
.footer-heading-text {
  margin-bottom: 15px;
}
.newsletter button {
  padding: 25px 30px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid var(--text);
  padding-top: 10px;
  font-size: 14px;

}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-section {
    margin: 0px;
    width: 100%;
    padding: 0px
  }

  .footer-section img {
    text-align: start !important;
  }
}