.homescreen-search-section {
    display: flex;
    width: auto;

}

.homescreen-search-left {
    width: 100%;
    padding: 50px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homescreen-search-right {
    width: 50%;
}

.searchdestination-background-image {
    background-image: var(--background);
    background-size: cover;
    /* Adjusts the size of the image */
    background-position: center;
    /* Centers the image */
    height: 250px;
    width: 100%;
    right: 0px;
    background-repeat: no-repeat;
}

.homescreen-searchbar {
    width: 420px;
}

.homescreen-tagbutton {
    display: flex;
    justify-content: space-between;
    /* width: 500px; */
}

.image-placeholder {
    width: 230px;
    height: 230px;
    background-color: var(--darkGray);
    border-radius: 50%;
    display: flex;
    z-index: 15;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}
.search-destination-placeholder {
    width: 280px;
    height: 280px;
    background-color: var(--darkGray);
    border-radius: 50%;
    display: flex;
    z-index: 15;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}

.homescreen-space-bwt {
    margin-bottom: 30px;
}

.profile-image-ltr {
    padding-top: 30px;
    padding-left: 180px;
}

.profile-image-rtl {
    padding-top: 30px;
    position: absolute;
    left: 12%;
}

/* new class */


.search-destination-classmain {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.search-destination-classleft {
    width: 50%;

}

.search-destination-classleft-paadingLeft {
    margin: 4%;
    margin-left: 12%;
}

.search-destination-classRight {
    align-items: center;
    width: 50%;
}
.searchdestination-logo-image {
    width: 215px;
    height: auto;
}

@media (max-width: 1024px) {
    .homescreen-search-section {
        padding: 2% 4%;
        flex-direction: column;
    }

    .homescreen-search-left {
        width: 100%;
    }

    .search-destination-classleft {
        padding: 4%;
        width: 100% !important;
    }

    .homescreen-search-right {
        display: none;
    }

    .homescreen-searchbar,
    .homescreen-tagbutton {
        width: 500px;
    }

    .image-placeholder {
        width: 180px;
        height: 180px;
    }

    .search-destination-classRight {
        display: none;
    }

    .search-destination-classleft-paadingLeft {
        margin: 4%;
        margin-top: 0%;
        margin-left: 3%;
    }

}

@media (max-width: 768px) {
    .homescreen-search-section {
        padding: 2% 2%;
        width: 100%;
    }

    .homescreen-space-bwt {
        margin-bottom: 20px;
    }

    .homescreen-searchbar {
        width: 100%;
    }

    .homescreen-tagbutton {
        width: 100%;
    }

    .image-placeholder {
        width: 150px;
        height: 150px;
    }

    .search-destination-classleft {
        padding: 4%;
        width: 100% !important;
    }

    .search-destination-classRight {
        display: none;
    }

    .search-destination-classleft-paadingLeft {
        margin: 4%;
        margin-top: 0%;
        margin-left: 3%;
    }
}

@media (max-width: 480px) {
    .homescreen-search-section {
        padding: 1% 2%;
    }

    .homescreen-search-left {
        width: 93%;
    }

    .homescreen-space-bwt {
        margin-bottom: 15px;
    }

    .homescreen-searchbar {
        width: 100%;
    }

    .homescreen-tagbutton {
        width: 100%;
    }

    .image-placeholder {
        width: 120px;
        height: 120px;
    }

    .search-destination-classRight {
        display: none;
    }

    .search-destination-classleft {
        width: 100% !important;
    }

    .search-destination-classleft-paadingLeft {
        margin: 4%;
        margin-top: 0%;
        margin-left: 5%;
    }
}