.payment-type-button {
    display: flex;
    width: 100%;
    cursor: pointer;
    background-color: aqua;
    padding: 5px 10px;
    align-items: center;
    margin: 10px 0px 2px 0px;
}

.payment-texts {
    display: flex;
    flex-direction: column;
}

.payment-type-icon {
    width: 80px;
    height: 80px;
    margin: 0px 10px;
}

.payment-method-container {
    min-width: 460px
}

.payment-backbutton-container {
    padding: 25px;
}

.Payment-Support_msg {
    margin: 10px 0px;
    padding: 10px;
}

@media (max-width: 1024px) {
    .payment-method-container {
        min-width: 100%
    }
}