.accordion {
  border-radius: 15px;
  margin: 10px 10px 20px 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: var(--lightenOrange);
  /* Background color for the accordion */
}

.accordion-header {
  padding: 20px;
  cursor: pointer;
  background-color: var(--lightenOrange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.accordion-header.open {
  background-color: var(--lightenOrange);
}

.accordion-downarrow {
  width: 24px;
}

.accordion-title {
  background-color: var(--lightblueBgColor);
  padding: 10px 60px;
  border-radius: 20px;
  font-family: 'Arial', sans-serif;
  /* Font style for the title */
  font-weight: bold;
  color: #492784;
  /* Font color for the title */
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: var(lightenOrange);
}

.accordion-inner-content {
  padding: 15px;
  background-color: var(--lightenOrange);
  /* color: #492784; */
  /* Font color for the content */
}

@media (max-width: 600px) {
  .accordion {
    margin: 5px;
  }

  .accordion-header {
    padding: 10px;
  }

  .accordion-title {
    padding: 8px 16px;
  }

  .accordion-inner-content {
    padding: 10px;
  }
}