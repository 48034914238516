.alphabet-links {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 10px 0px;
  padding: 1rem;
  background-color: var(--mainBgColor);
}

.alphabet-links a {
  padding: 0.5rem 0.5rem;
  text-decoration: none;
  color: #979797;
  border-bottom: 2px solid var(--darkGray);
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.alphabet-links a:hover {
  color: #000;
  border-bottom-color: var(--darkGray);
}

.alphabet-links a.active {
  color: #fff;
  background-color: var(--mainColor);
  border-bottom-color: var(--mainColor)
}

@media (max-width: 600px) {
  .alphabet-links a {
    padding: 0.3rem 0.6rem;
  }

  .alphabet-links {
    margin: 0px 0px 0px 0px;
  }

}