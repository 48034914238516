.Nodata-logo{
    width: 30%;
 
}
.Nodata-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: 5px;
    background-color: var(--text);
    flex-direction: column;
}
.Nodata-Message{
    padding:25px 10px 10px 10px;
    text-align: center;

}