/* TabComponent.css */
.tabs {
    display: flex;
    border: 1px solid var(--whiteColor);
    border-radius: 25px;
    overflow: hidden;
    background-color: var(--mainColor);
    width: fit-content;
    margin: 20px;
    padding: 5px;
  }
  
  .tab {
    padding: 10px 25px;
    background-color: var(--mainColor);
    color: var(--whiteColor);
    cursor: pointer;
    text-align: center;
  }
  
  .tab.selected {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    border-radius: 25px;
  }
  

  @media (max-width: 600px) {
    .tabs {
      width: 100%;
      margin: 10px 0px;
      padding: 5px;
    }
    .tab {
      width: 100%;
    }
  
  }

  @media (max-width: 450px) {
    .tab {
      width: 100%;
      padding: 10px 20px;

    }
  }