.main {
  margin-top: 65px;
  padding: 4px 0px;
  background-color: var(--mainBgColor);
}


body {
  margin: 0;
}

.app-main-container {
  padding: 0px 5%;
}

.Breadcums-div {
  margin: 15px 0px 15px 0px;
}


.searchdestination1 {
  background-image: var(--background);
  background-size: cover;
  background-position: center;
  height: 300px;
  /* Adjust height as needed */
  width: 300px;
  /* Adjust width as needed */
  /* Flip horizontally */
  /* Optional: Add transition for smooth effect */
  transition: transform 0.3s ease;
}

@media screen and (min-width: 1600px) {
  .main {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* scroll style */

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--mainColor);
  outline: 1px solid var(--mainColor);
}

.MuiDialog-root{
  z-index: 99999999 !important;
}