.destinationplaceholder-main {
    text-align: center;
    background-color: var(--mainColor);
    padding: 2% 5%;
}

.destinationp-content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 auto;
}

.destinationp-card {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 370px;
    flex: 1 1 300px;
    max-width: 100%;
}
.destinationplaceholder-headertext {
    text-align: left;
}
.image-placeholder-x {
    width: 100%;
    height: 300px;
    background-color: #d3d3d3;
    border-radius: 15px;
    margin-bottom: 20px;
}
.destinationplaceholder-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-align {
    margin-top: 80px;
}

@media (max-width: 768px) {
    .destinationp-content {
        flex-direction: column;
        gap: 20px;
    }

    .destinationp-card {
        max-width: 100%;
    }

    .image-placeholder-x {
        width: 100%;
        height: 200px;
    }

    .card-align {
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .image-placeholder-x {
        width: 100%;
        height: 150px;
    }
    .destinationplaceholder-header {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        
    }
}