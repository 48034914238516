/* General Container */
.filter-container {
  font-family: "Arial", sans-serif;
  position: relative;
}




/* Modal Styling */
.filter-modal {
  position: fixed;
  top: 0;
  right: -100%;
  /* Hidden by default */
  width: 350px;
  height: 100%;
  background: var(--card);
  box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  /* Slide-in animation */
}

.filter-modal.open {
  right: 0;
  /* Slide in when open */
}

/* Modal Header */
.FilterModal-header {
  padding: 20px;
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--blackColor)
}



.close-button {
  background: none;
  border: none;
  font-size: 25px;
  color: var(--whiteColor);
  cursor: pointer;
}

/* Modal Content */
.FilterModal-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.filter-section label {
  display: block;
  margin-bottom: 8px;
}

.FilterModal-filterdiv {
  display: flex;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
}

.FilterModal-filterbutton {
  /* display: flex; */
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
  background-color: var(--mainColor);
  padding: 10px;
  width: 95px;
  cursor: pointer;
  border-radius: 25px;
  justify-content: 'center';
  align-items: 'center';
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

/* .FilterModal-filterbutton:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: var(--greenColor);
  color: var(--mainColor);
  animation: fade-in 0.3s ease;
} */

.FilterModal-filterbuttonSeleteted {
  width: 100%;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  width: 95px;
  cursor: pointer;
  border-radius: 25px;
  justify-content: 'center';
  align-items: 'center';
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: var(--greenColor);
  color: var(--mainColor);
  animation: fade-in 0.3s ease;
}

/* Modal Footer */
.FilterModal-footer {
  padding: 20px;
  background: var(--blackColor);
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  border-radius: 15px;
}

.apply-button,
.clear-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}



/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Smooth Overlay Fade */
.overlay {
  animation: fade-in 0.5s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}