.alert-overlay {
  position: fixed;
  /* Keep the overlay fixed */
  top: 0;
  /* Position it at the top */
  left: 0;
  /* Position it at the left */
  right: 0;
  /* Position it at the right */
  bottom: 0;
  /* Position it at the bottom */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  /* Use flexbox to center content */
  justify-content: center;
  /* Center content horizontally */
  align-items: center;
  /* Center content vertically */
  z-index: 500000;
  /* Ensure it appears above all other content */
}

.alert-overlay {
  transition: opacity 0.3s ease;
  /* Smooth transition for the overlay */
}

.alert-container {
  background-color: var(--card);
  padding: 25px 20px 20px 20px ;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  /* Make sure child elements can be positioned */
  opacity: 0;
  /* Initially hidden */
  transform: translateY(-20px);
  /* Move up for effect */
  animation: fadeIn 0.3s forwards;
  /* Apply animation */
}

.close-icon {
  position: absolute;
  top: -12px;
  right: 4px;
  cursor: pointer;
  font-size: 32px;
  /* Adjust size as needed */
  color: #f44336;
  /* Optional: color for the close icon */
}

.alert-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  /* Center the continue button */
}

.continue-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  background-color: var(--greenColor);
  /* Green color */
  color: white;
}

.continue-btn:hover {
  opacity: 0.9;
}

/* Animation keyframes */
@keyframes fadeIn {
  to {
    opacity: 1;
    /* Fade in */
    transform: translateY(0);
    /* Move back to original position */
  }
}