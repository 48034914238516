.destinationfaq-main {
    text-align: center;
    padding: 4% 5%;
}

.faq-body {
    width: 100%;
    padding: 20px 0px;
}

.faq-button {
    text-align: left;
    margin: 0px 30px;
}