.Packages-Bundalmain {
  display: flex;
  gap: 10px;
  height: 80vh;
  width: 100%;
  width: 90vw;
  /* min-width: 1200px */
}

.Packages-Bundle-list {
  width: 50%;
}

.Packages-Selected-Bundle {
  width: 50%;
  overflow: auto;
}

.package-searchbar {
  margin: 10px 25%;
}

@media (max-width: 1024px) {
  .Packages-Bundalmain {
    flex-direction: column;
    overflow: auto;
    width: 82vw;
  }

  .hide-on-mobile {
    display: none;
  }

  .Packages-Bundle-list {
    width: 100%;
    margin-bottom: 100px;

  }

  .Packages-Selected-Bundle {
    width: 100%;
    overflow: auto;
  }

  .package-searchbar {
    margin: 25px 2%;
  }
}

@media (max-width: 767px) {
  .Packages-Bundalmain {
    flex-direction: column;
    overflow: auto;
    width: 82vw;
  }

  .hide-on-mobile {
    display: none;
  }

  .Packages-Bundle-list {
    width: 88%;
    margin-bottom: 100px;

  }

  .Packages-Selected-Bundle {
    width: 87%;
    overflow: auto;
  }

  .package-searchbar {
    margin: 25px 2%;
  }
}