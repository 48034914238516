.editprofile-container {
    margin: 20px 0px;
  }
  
  .form-group {
    margin-bottom: 20px;
    width: 100%;
  }
  .editprofile_heading {
    margin: 20px 0px;
  }
 
  .editprofile-button {
    margin-top: 10px;
}
.edit-profile-container {
  width: 500px;
}

@media (max-width: 600px) {
  .edit-profile-container {
    width: 400px;
  }
}
@media (max-width: 500px) {
  .edit-profile-container {
    width: 300px;
  }
}
@media (max-width: 400px) {
  .edit-profile-container {
    width: 100%;
  }
}