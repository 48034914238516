.custom-checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
}

/* Hide the actual checkbox input */
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Label that displays as the custom checkbox */
.custom-checkbox input[type="checkbox"]+label {
    position: relative;
    padding-left: 30px;
    /* Space for the checkbox icon */
    line-height: 20px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

/* Unchecked state: Custom checkbox box */
.custom-checkbox input[type="checkbox"]+label:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #aaa;
    border-radius: 4px;
    background-color: #fff;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

/* Checked state: Add checkmark and change border */
.custom-checkbox input[type="checkbox"]:checked+label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 3px;
    /* Slight offset inside the checkbox */
    width: 12px;
    border: none;
    height: 12px;
    background-image: url(../../../assets/images/check-blue-ic.png);
    /* Add your checkmark icon here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

/* Optional: Change background and border color when checked */
.custom-checkbox input[type="checkbox"]:checked+label:before {
    background-color: #007bff;
    /* Change to your preferred checked color */
    border-color: #007bff;
}