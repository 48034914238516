.forgot-password-container {
    margin: 20px 0px;
    width: 400px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  .Forget_heading {
    margin: 20px 0px;
  }
  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .separator {
    display: flex;
    align-items: center;
    justify-content: center;    
    margin: 10px 0;
  }
  
  .separator .line {
    flex-grow: 1;
    border-bottom: 1px dashed #aaa;
    margin: 0 10px;
  }
  
  .separator span {
    font-size: 14px;
    color: #aaa;
  }
  .forgetpassword-button {
    margin-top: 10px;
}


@media (max-width: 500px) {
  .forgot-password-container {
    width: 300px;
  }
}
@media (max-width: 400px) {
  .forgot-password-container {
    width: 305px;
  }
}