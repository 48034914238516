.SubHeader-main {
  background-color: var(--mainColor);
  padding: 20px 15px;
  border-radius: 15px;
  text-align: center;
}

.SubHeader-SearchBar {
  padding: 1% 25%;
}

@media (max-width: 768px) {
  .SubHeader-SearchBar {
      padding: 2% 2%;
  }
}