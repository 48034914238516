.progress-bar {
    position: relative;
    height: 15px;
    width: 100%;
    background-color:var(--card);
    border: 1px solid var(--grayColor);
    border-radius: 15px;
    overflow: hidden;
  }
  
  .progress-fill {
    height: 100%;
    background-color: var(--greenColor); /* Replace with your AppConstants color */
    transition: width 0.5s ease; /* Smooth animation */
  }
  
  .txt-action-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px; /* Replace with your AppConstants font size */
    color: #999; /* Replace with your AppConstants color */
    font-family: Arial, sans-serif; /* Replace with your AppConstants font family */
  }
  