.label-container {
  display: flex;
  color: var(--text);
  align-items: center;
  border: 1px solid var(--PurpleColor);
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px;
  background-color: transparent;
  /* cursor: pointer; */
}
  .label-icon {
    margin-right: 5px;
    width: 20px; 
    height: 20px; 
  }
  
  .label-text {
   min-width: 60px;
  }
  .Tagbutton-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }