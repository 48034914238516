.checkbox-lable{
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 10px;

}
.check-lable{
    padding-left: 10px;
    font-size: 14px;
}
.chackbox-error-line{
    color: var(--closeBtnColor);
    font-size: 14px;
}




.checkbox-design{
  display: flex;
  flex-wrap: wrap;
}

  .checkbox-wrapper-4 * {
    box-sizing: border-box;
  }
  .checkbox-wrapper-4{
    width: 50%;
  }
  .checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: inline-block;
  }
  .checkbox-wrapper-4 .cbx:not(:last-child) {
    margin-right: 6px;
  }
  .checkbox-wrapper-4 .cbx:hover {
    background: rgba(0,119,255,0.06);
  }
  .checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0,16,75,0.05);
  }
  .checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    line-height: 18px;
  }
  .checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: var(--mainColor);
  }
  .checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
  }
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
    background: var(--mainColor);
    border-color: var(--mainColor);
    animation: wave-4 0.4s ease;
  }
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
  @media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
      width: 100%;
      display: inline-block;
    }
  }
  @-moz-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-o-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }












/* .checkbox-container {
  display: inline-block;
  position: relative;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px; /* Add space for the checkmark */



/* .checkbox.style-c {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox.style-c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox.style-c input:checked ~ .checkbox__checkmark {
    background-color: #f7cb15;
  }
  .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
  }
  .checkbox.style-c:hover input ~ .checkbox__checkmark {
    background-color: #eee;
  }
  .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
    background-color: #f7cb15;
  }
  .checkbox.style-c .checkbox__checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    transition: background-color 0.25s ease;
    border-radius: 4px;
  }
  .checkbox.style-c .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  .checkbox.style-c .checkbox__body {
    color: #333;
    line-height: 1.4;
    font-size: 16px;
  } */