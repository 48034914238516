.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 0px;
    min-width: 600px;
    /* background-color: var(--mainBgColor); */

}

.divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    text-align: center;
}

.line {
    flex: 1;
    border-bottom: 1px solid var(--darkGray);
}

.divider-text {
    margin: 0 10px;

}

.login-left {
    flex: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-placeholder {
    width: 150px;
    height: 150px;
    background-color: var(--darkGray);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-icon {
    font-size: 48px;
    color: #888;
}

.login-right {
    flex: 1;
    width: 50%;
    padding: 20px;
    background-color: 'transparent';
    border-radius: 8px;
}

.signup-heading {
    margin-bottom: 20px;
}

.google-facbook-container {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.input-group {
    margin-bottom: 20px;
}

.forgot-password {
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;
}

.alternative-signin {
    text-align: center;
    margin-top: 20px;
}

.alternative-signin {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-facebook-button-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 50px;
    background-color: var(--card);
    border: 1px solid var(--darkGray);
    border-radius: 10px;
    color: black;
    font-size: 14px;
    cursor: pointer;
}

.fb_icon {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.my-google-button-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px !important;
    background-color: var(--card);
    border-radius: 10px !important;
    color: black !important;
    font-weight: 600 !important;
    border: 1px solid var(--darkGray) !important;
    font-size: 24px;
    cursor: pointer;
    box-shadow: none !important;
}

.my-google-button-class>div {
    padding: 0 !important;
}
/* Styling for the error message container */
.error-message {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Light red border */
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

/* Optional: Add an icon for error */
.error-message .error-icon {
    margin-right: 8px;
    font-size: 16px;
    color: #721c24;
}

/* For screens 1024px and below */
@media (min-width: 1200px) and (max-width: 1550px) {
    .login-container {
        min-width: 600px;
    }
}

@media (max-width: 1024px) {
  

    .login-left,
    .login-right {
        width: 100%;
        padding: 10px;
    }

    .image-placeholder {
        width: 250px;
        height: 250px;
    }

    .image-icon {
        font-size: 42px;
    }

    .signup-heading {
        font-size: 20px;
    }

    .input-group {
        margin-bottom: 15px;
    }

    .google-facbook-container {
        flex-direction: column;
        gap: 10px;
    }

    .my-facebook-button-class,
    .my-google-button-class {
        height: 50px;
        font-size: 14px;
    }

    .fb_icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  
    .login-right {
        width: 50%;
        padding: 10px;
    }

    .image-placeholder {
        width: 200px;
        height: 200px;
    }

    .image-icon {
        font-size: 36px;
    }

    .signup-heading {
        font-size: 18px;
    }

    .input-group {
        margin-bottom: 15px;
    }

    .google-facbook-container {
        flex-direction: column;
        gap: 10px;
    }



}
@media (max-width: 708px) {
   
    .login-container{
        min-width: 500px;
    }
}
@media (max-width: 608px) {
   
    .login-container{
        min-width: 450px;
    }
}
/* For screens 480px and below */
@media (max-width: 480px) {
    .signup-heading {
        font-size: 16px;
    }

    .input-group {
        margin-bottom: 10px;
    }

    .google-facbook-container {
        gap: 5px;
    }
    .login-container{
        min-width: 300px;
    }

}
@media (max-width: 400px) {
  
    .login-container{
        width: 100%;
        min-width: 200px;
    }
}