.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 0px;
}


.divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    text-align: center;
}

.line {
    flex: 1;
    border-bottom: 1px solid var(--darkGray);
}

.divider-text {
    margin: 0 10px;

}



.login-left {
    flex: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-placeholder {
    width: 300px;
    height: 300px;
    background-color: var(--darkGray);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-icon {
    font-size: 48px;
    color: #888;
}

.input-textfield {
    width: 100%;
}

.input-xalign {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.signup-checkbox {
    display: flex;
    /* gap: 10px; */
}

.large-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.login-right {
    flex: 1;
    width: 50%;
    padding: 20px;
    background-color: var(--mainBgColor);
    border-radius: 8px;
}

.signup-heading {
    margin-bottom: 20px;
}

.google-facbook-container {
    display: flex;
    gap: 15px;
}

.input-group {
    margin-bottom: 20px;
}


.forgot-password {
    text-align: left;
    margin-bottom: 20px;
}

.alternative-signin {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-facebook-button-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 50px;
    background-color: var(--whiteColor);
    border: 1px solid var(--darkGray);
    border-radius: 10px;
    color: black;
    font-size: 14px;
    cursor: pointer;
}

.fb_icon {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.my-google-button-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px !important;
    background-color: var(--whiteColor);
    border-radius: 10px !important;
    color: black !important;
    font-weight: 600 !important;
    border: 1px solid var(--darkGray) !important;
    font-size: 24px;
    cursor: pointer;
    box-shadow: none !important;
}

.my-google-button-class>div {
    padding: 0 !important;
}

/* For screens 1024px and below */
@media (min-width: 1200px) and (max-width: 1550px) {
    .login-container {
        min-width: 800px;
    }
}

@media (max-width: 1024px) {


    .login-left,
    .login-right {
        width: 100%;
        padding: 10px;
    }

    .image-placeholder {
        width: 250px;
        height: 250px;
    }

    .image-icon {
        font-size: 42px;
    }

    .signup-heading {
        font-size: 20px;
    }

    .input-group {
        margin-bottom: 15px;
    }

    .google-facbook-container {
        flex-direction: column;
        gap: 10px;
    }

    .my-facebook-button-class,
    .my-google-button-class {
        height: 45px;
        font-size: 14px;
    }

    .fb_icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}

/* For screens 768px and below */
@media (max-width: 768px) {


    .login-left,
    .login-right {
        width: 100%;
        padding: 10px;
    }

    .image-placeholder {
        width: 200px;
        height: 200px;
    }

    .image-icon {
        font-size: 36px;
    }

    .signup-heading {
        font-size: 18px;
    }

    .input-group {
        margin-bottom: 20px;
    }

    .google-facbook-container {
        flex-direction: column;
        gap: 10px;
    }

    .my-facebook-button-class,
    .my-google-button-class {
        height: 40px;
        font-size: 12px;
    }

    .fb_icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
}

@media (max-width: 708px) {

    .login-container {
        min-width: 500px;
    }
}

@media (max-width: 608px) {

    .login-container {
        min-width: 380px;
    }

    .input-xalign {
        display: flex;
        flex-direction: column;
    }
}

/* For screens 480px and below */
@media (max-width: 480px) {
    .signup-heading {
        font-size: 16px;
    }

    .input-group {
        margin-bottom: 20px;
    }

    .google-facbook-container {
        gap: 5px;
    }

    .my-facebook-button-class,
    .my-google-button-class {
        height: 35px;
        font-size: 10px;
    }

    .fb_icon {
        width: 16px;
        height: 16px;
        margin-right: 3px;
    }

    .login-container {
        min-width: 340px;
    }
}

@media (max-width: 400px) {

    .login-container {
        width: 100%;
        min-width: 305px;
    }
}